import React, { Component, useState, useEffect, useRef, useLayoutEffect } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link } from 'react-router-dom'
import { motion, useAnimation } from 'framer-motion';
import Logo from '../assets/PromptLogo.png'
import Accordion from 'react-bootstrap/Accordion';

import discord from '../assets/discord.png'
import twitter from '../assets/twitter.png'

const FAQ = (props) => {

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div>
      <div style={{margin: 'auto', maxWidth: '900px', marginTop: '200px', width: '100%', textAlign: 'center'}}>
        <span style={{fontWeight: '500', fontSize: '70px', letterSpacing: '-2px', lineHeight: 1.0}}>Frequently Asked Questions</span>
      </div>
      <div style={{margin: 'auto', maxWidth: '600px', marginTop: '30px', width: '100%'}}>
        <span style={{fontWeight: '400', fontSize: '20px', letterSpacing: '-0.3px', lineHeight: 1.5, color: 'rgb(156, 163, 175)'}}>Clear up any concerns, and use AI the smarter way.</span>
      </div>
      <div style={{margin: 'auto', maxWidth: '900px', marginTop: '30px', textAlign: 'left', width: '90%'}}>
        <Accordion>
          <Accordion.Item eventKey="0">
            <Accordion.Header>What AI's/LLM's does PromptCache support?</Accordion.Header>
            <Accordion.Body>
            PromptCache supports all large language models (LLMs) and extends to other AI technologies, including image generation tools like Stable Diffusion. Our platform is designed to be highly flexible, allowing seamless integration with a wide range of AI systems. This ensures you can work with the best tools available, whether you are generating text, images, or other types of AI-driven content.
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="1">
            <Accordion.Header>Are there usage limits with PromptCache?</Accordion.Header>
            <Accordion.Body>
            There are no usage limits with PromptCache, providing you with unlimited access to store and manage your prompts. However, the cost for the service depends on the number of seats or users added to your plan. This flexible pricing model allows you to scale the service according to your team’s needs without worrying about hitting usage caps.
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="2">
            <Accordion.Header>How does PromptCache ensure the security of my data?</Accordion.Header>
            <Accordion.Body>
            Security is a critical aspect of PromptCache. We implement industry-standard encryption for data both at rest and in transit, ensuring that all your information is secure. Our platform also includes multi-factor authentication, regular security audits, and strict access control measures to protect your data. We are committed to maintaining compliance with relevant data protection regulations, so you can trust that your data is safe with us.
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="3">
            <Accordion.Header>How does PromptCache support collaboration and team usage?</Accordion.Header>
            <Accordion.Body>
            PromptCache is built for collaboration, enabling teams to easily share, manage, and develop prompts together. You can set up shared libraries, assign specific roles and permissions, and track changes, facilitating smooth teamwork and communication. This collaborative environment helps eliminate duplication, ensures consistency, and allows everyone in your organization to contribute effectively.
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="4">
            <Accordion.Header>How can PromptCache help my team improve productivity and efficiency?</Accordion.Header>
            <Accordion.Body>
            PromptCache enhances productivity and efficiency by centralizing prompt management and providing an intuitive interface for easy access. The platform’s advanced search capabilities allow for quick retrieval and reuse of prompts, reducing the time spent on repetitive tasks. Collaboration features streamline the workflow, ensuring that teams can work together seamlessly. These tools enable your team to focus on delivering high-quality work, rather than getting bogged down by administrative tasks.
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="5">
            <Accordion.Header>What are the future plans for new features for PromptCache?</Accordion.Header>
            <Accordion.Body>
            PromptCache has an exciting roadmap for future enhancements, including AI Prompt Improvement to refine prompt quality, Data Storage Referencing for efficient data management, and Multiple LLM Prompt Testing to optimize prompts across different models. These features are designed to keep PromptCache at the cutting edge of prompt management technology, continually improving the user experience and functionality.
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>
    </div>
    
  )
}

export default FAQ
