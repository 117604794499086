import React, { Component, useState, useEffect, useRef, useLayoutEffect } from 'react'
import Accordion from 'react-bootstrap/Accordion';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link, useLocation } from 'react-router-dom'
import { motion, useAnimation } from 'framer-motion';

import Logo from '../assets/PromptLogo.png'
import tempVideo from '../assets/tempVideoPlaceholder.png'

import prompt1 from '../assets/prompt1.png'
import prompt2 from '../assets/prompt2.png'

import doc from '../assets/document.png'
import department from '../assets/department.png'
import share from '../assets/share.png'
import improve from '../assets/improve.png'

import discord from '../assets/discord.png'
import twitter from '../assets/twitter.png'

import np1 from '../assets/new-prompt1.png'
import np2 from '../assets/new-prompt2.png'
import np3 from '../assets/new-prompt3.png'

import HeroVideo from '../assets/FinishVideo.mp4'
import Share from '../assets/Sharing.mp4'
import Improving from '../assets/Improving.mp4'

import Navbar from '../elements/Navbar'
import Footer from '../elements/Footer'
import FAQ from '../elements/FAQ'

const Home = (props) => {

  const controls = useAnimation();
  const location = useLocation(); 
  const [key, setKey] = useState(0); // State to force re-render

    const prompts = [
      { department: 'Engineering', title: 'Prepare Proposal for Client', date: 'Aug 4, 2024', initials: 'JB', color: '#6B8E23' },
      { department: 'Marketing', title: 'Create Social Media Strategy', date: 'Sep 10, 2024', initials: 'AL', color: '#4682B4' },
      { department: 'Sales', title: 'Develop Sales Pitch', date: 'Jul 15, 2024', initials: 'MR', color: '#DAA520' },
      { department: 'HR', title: 'Organize Team Building Event', date: 'Nov 2, 2024', initials: 'KM', color: '#FF6347' },
      { department: 'Finance', title: 'Analyze Quarterly Financials', date: 'Dec 20, 2024', initials: 'CG', color: '#5F9EA0' },
      { department: 'Engineering', title: 'Code Review for New Feature', date: 'Aug 18, 2024', initials: 'SD', color: '#8A2BE2' },
      { department: 'Design', title: 'Update Website UI', date: 'Oct 5, 2024', initials: 'LH', color: '#D2691E' },
      { department: 'Product', title: 'Research Market Trends', date: 'Jan 25, 2024', initials: 'ZK', color: '#556B2F' },
      { department: 'Operations', title: 'Optimize Supply Chain', date: 'May 14, 2024', initials: 'TW', color: '#8B4513' },
      { department: 'Customer Support', title: 'Implement New Support Protocols', date: 'Apr 22, 2024', initials: 'EN', color: '#FF4500' },
      { department: 'Engineering', title: 'Deploy Software Update', date: 'Jun 30, 2024', initials: 'AV', color: '#2E8B57' },
      { department: 'Marketing', title: 'Launch Email Campaign', date: 'Jul 9, 2024', initials: 'DF', color: '#B22222' },
      { department: 'Sales', title: 'Prepare Client Presentation', date: 'Feb 13, 2024', initials: 'BT', color: '#9400D3' },
      { department: 'HR', title: 'Conduct Employee Survey', date: 'Mar 1, 2024', initials: 'MJ', color: '#FFD700' },
      { department: 'Finance', title: 'Review Budget Allocations', date: 'Sep 28, 2024', initials: 'RS', color: '#FF1493' },
      { department: 'Design', title: 'Create New Brand Assets', date: 'Nov 15, 2024', initials: 'PL', color: '#00CED1' },
      { department: 'Product', title: 'Develop Product Roadmap', date: 'Dec 3, 2024', initials: 'HN', color: '#4B0082' },
      { department: 'Operations', title: 'Audit Operational Processes', date: 'Oct 21, 2024', initials: 'VG', color: '#20B2AA' },
      { department: 'Customer Support', title: 'Improve Response Times', date: 'May 7, 2024', initials: 'KY', color: '#FF8C00' },
      { department: 'Engineering', title: 'Implement Security Enhancements', date: 'Aug 25, 2024', initials: 'NW', color: '#4682B4' },
    ];
  
    useLayoutEffect(() => {
      let isActive = true;
  
      const sequence = async () => {
        if (isActive) {
          // Pan to the right
          await controls.start({
            x: -2750,
            transition: { duration: 35, ease: 'linear' },
          });
  
          // Pan back to the left
          await controls.start({
            x: 2750,
            transition: { duration: 35, ease: 'linear' },
          });
        }
      };
  
      if (isActive) {
        sequence();
      }
  
      return () => {
        controls.stop();
        isActive = false;
      };
    }, [controls, key]); // Include 'key' to trigger reinitialization
  
    useEffect(() => {
      // Trigger a key change whenever the location changes, forcing the animation to reset
      setKey(prevKey => prevKey + 1);
    }, [location]);

  const images = [np1, np2, np3];

  const containerRef = React.useRef(null);
  const [containerWidth, setContainerWidth] = React.useState(0);

  const containerRef2 = React.useRef(null);
  const [containerHeight, setContainerHeight] = React.useState(0);

  const fakeDepartments = [
    { name: 'Marketing', color: '#4682B4' },
    { name: 'Sales', color: '#FF8C00' },
    { name: 'HR', color: '#20B2AA' },
    { name: 'Engineering', color: '#4B0082' },
    { name: 'Finance', color: '#00CED1' },
    { name: 'Support', color: '#FF1493' },
    { name: 'IT', color: '#FFD700' },
  ];
  
  React.useEffect(() => {
    if (containerRef.current) {
      setContainerWidth(containerRef.current.scrollWidth);
    }
    if (containerRef2.current) {
      setContainerHeight(containerRef2.current.scrollWidth);
    }
  }, []);

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const contentRef = useRef(null)

  const scrollToContent = () => {
    if (contentRef.current) {
      contentRef.current.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
          inline: 'center',
      });
    }
  };

    return (
        <div style={{width: '99.1vw', height: '100vh', backgroundColor: window.colors.white, fontFamily: 'inter', overflowX: 'hidden'}}>
          <div style={{maxWidth: '100%', display: 'flex', flexDirection: 'column', marginLeft: 'auto', marginRight: 'auto'}}>
            
            {/*Navbar*/}
            <Navbar></Navbar>

            <div style={{marginTop: '35px', width: '100%', textAlign: 'center'}}>

              {/*Hero*/}
              <div style={{margin: 'auto', maxWidth: '780px', marginTop: '30px', width: '100%'}}>
                <span style={{fontWeight: '500', fontSize: '70px', letterSpacing: '-2px', lineHeight: 1.0}}>All-in-One AI Platform for Prompt Management</span>
              </div>
              <div style={{margin: 'auto', maxWidth: '600px', marginTop: '30px', width: '100%'}}>
                <span style={{fontWeight: '400', fontSize: '20px', letterSpacing: '-0.3px', lineHeight: 1.5, color: 'rgb(156, 163, 175)'}}>A platform for businesses to share and manage prompts, enhancing team collaboration and productivity through a centralized, secure library for easy access and organization.</span>
              </div>
              <button onClick={() => scrollToContent()} style={{fontFamily: 'inter', backgroundColor: window.colors.black, color: window.colors.white, padding: '10px 20px 10px 20px', fontWeight: '600', borderRadius: '5px', marginTop: '25px'}}>Explore our Platform</button>
              <div style={{width: '80%', height: 'auto', margin: 'auto', marginTop: '50px', maxWidth: '1100px'}}>
                <video autoPlay muted loop style={{width: '100%', border: '1px solid rgb(226, 232, 240)', borderRadius: '10px', boxShadow: '0px 0px 16px -10px #000000'}}>
                  <source src={HeroVideo} type='video/mp4'></source>
                </video>
              </div>

              {/*Sharing*/}
              <div ref={contentRef} style={{margin: 'auto', maxWidth: '1100px', marginTop: '200px', width: '100%'}}>
                <span style={{fontWeight: '500', fontSize: '70px', letterSpacing: '-2px', lineHeight: 1.0, background: 'linear-gradient(to right, rgb(37, 99, 235), rgb(168, 85, 247))', WebkitBackgroundClip: 'text', WebkitTextFillColor: 'transparent'}}>Sharing and Collaboration</span>
              </div>
              <div style={{margin: 'auto', maxWidth: '600px', marginTop: '30px', width: '100%'}}>
                <span style={{fontWeight: '400', fontSize: '20px', letterSpacing: '-0.3px', lineHeight: 1.5, color: 'rgb(156, 163, 175)'}}>Create a department and assign the prompt to it.</span>
              </div>
              <div style={{margin: 'auto', marginTop: '30px', display: 'flex', flexDirection: windowWidth < 1200 ? 'column':'row', justifyContent: 'center'}}>
                <img src={prompt2} style={{border: '1px solid rgb(226, 232, 240)', borderRadius: '10px', boxShadow: '0px 0px 16px -10px #000000', maxWidth: '490px', margin: windowWidth < 1200 ? 'auto':'20px', width: '80%'}}></img>
                {windowWidth < 1200 && <div style={{height: '20px'}}></div>}
                <img src={prompt1} style={{border: '1px solid rgb(226, 232, 240)', borderRadius: '10px', boxShadow: '0px 0px 16px -10px #000000', maxWidth: '490px', margin: windowWidth < 1200 ? 'auto':'20px', width: '80%'}}></img>
              </div>

              {/*Prebuilt*/}
              <div style={{margin: 'auto', width: '100%', marginTop: '200px', maxWidth: '1000px'}}>
                <span style={{fontWeight: '500', fontSize: '70px', letterSpacing: '-2px', lineHeight: 1.0, background: 'linear-gradient(to right, rgb(147, 51, 234), rgb(236, 72, 153))', WebkitBackgroundClip: 'text', WebkitTextFillColor: 'transparent'}}>Prebuilt Prompts to Start</span>
              </div>
              <div style={{margin: 'auto', maxWidth: '600px', marginTop: '30px', width: '100%'}}>
                <span style={{fontWeight: '400', fontSize: '20px', letterSpacing: '-0.3px', lineHeight: 1.5, color: 'rgb(156, 163, 175)'}}>Jumpstart your efficiency.</span>
              </div>
              <motion.div animate={controls} className='noScroll' style={{margin: 'auto', marginTop: '30px', display: 'flex', flexDirection: 'row', justifyContent: 'center', width: '100%', overflow: 'visible'}}>
                {prompts.map((prompt, index) => {
                  return (
                    <div style={{minWidth: '320px', height: '190px', boxShadow: '0px 0px 10px -7px #000000', borderRadius: '10px', margin: '20px', padding: '20px', boxSizing:'border-box', display: 'flex', flexDirection: 'column', textAlign: 'left'}}>
                      <span style={{fontWeight: '600', color: prompt.color}}>{prompt.department}</span>
                      <span style={{fontWeight: '600', color: 'black', fontSize: '20px', marginTop: '10px'}}>{prompt.title}</span>
                      <div style={{display: 'flex', flexDirection: 'row', marginTop: 'auto'}}>
                        <span>{prompt.date}</span>
                        <div className='center' style={{width: '26px', height: '26px', backgroundColor: 'grey', color: 'white', borderRadius: '100px', marginLeft: 'auto'}}>
                          <span>{prompt.initials}</span>
                        </div>
                      </div>
                    </div>
                  )
                })}
              </motion.div>

              {/*Optimize*/}
              <div style={{margin: 'auto', maxWidth: '1000px', marginTop: '200px', width: '100%'}}>
                <span style={{fontWeight: '500', fontSize: '70px', letterSpacing: '-2px', lineHeight: 1.0, background: 'linear-gradient(to right, rgb(22, 163, 74), rgb(59, 130, 246))', WebkitBackgroundClip: 'text', WebkitTextFillColor: 'transparent'}}>Optimize Your Workflow with Prompt Management</span>
              </div>
              <div style={{margin: 'auto', maxWidth: '600px', marginTop: '30px', width: '100%'}}>
                <span style={{fontWeight: '400', fontSize: '20px', letterSpacing: '-0.3px', lineHeight: 1.5, color: 'rgb(156, 163, 175)'}}>Unlock the power of prompt collaboration.</span>
              </div>
              <div style={{margin: 'auto', width: windowWidth < 880 ? '100%' : '800px', marginTop: '50px', display: 'flex', flexDirection: windowWidth < 880 ? 'column' : 'row', flexWrap: 'wrap'}}>
                <div style={{position: 'relative', width: windowWidth < 880 ? '80%' : '30%', height: '350px', margin: windowWidth < 880 ? 'auto':'10px', boxShadow: '0px 0px 10px -7px #000000', borderRadius: '10px', padding: '20px', boxSizing: 'border-box', display: 'flex', flexDirection: 'column', justifyContent: 'end', textAlign: 'left'}}>
                    <div ref={containerRef} style={{width: '100%', height: '100%', position: 'absolute', top: 0, left: 0, overflow: 'hidden', zIndex: 1, filter: 'blur(0.75px)', backgroundColor: 'rgba(255, 255, 255, 0.5)', display: 'flex', flexDirection: 'row'}}>
                        <motion.div style={{display: 'flex'}} animate={{x: [-containerWidth / 2, 0]}} transition={{x: {repeat: Infinity, repeatType: 'loop', duration: 15, ease: 'linear'}}}>
                            {images.map((src, index) => (
                                <img key={index} src={src} style={{width: '220px', height: '177px', marginTop: '20px', boxShadow: '0px 0px 10px -7px #000000', marginRight: '20px'}} />
                            ))}
                            {images.map((src, index) => (
                                <img key={`${index}-duplicate`} src={src} style={{width: '220px', height: '177px', marginTop: '20px', boxShadow: '0px 0px 10px -7px #000000', marginRight: '20px'}} />
                            ))}
                        </motion.div>
                    </div>
                    <img src={doc} style={{width: '50px', marginBottom: '10px', zIndex: 2}}></img>
                    <span style={{fontWeight: '600', fontSize: '20px', marginBottom: '10px', zIndex: 2}}>Create Prompts</span>
                    <span style={{fontWeight: '400', fontSize: '16px', letterSpacing: '-0.3px', lineHeight: 1.5, color: 'rgb(156, 163, 175)', zIndex: 2}}>Create prompts and title then to never lose them</span>
                </div>
                {windowWidth < 880 && <div style={{height: '30px'}}></div>}
                <div style={{position: 'relative', width: windowWidth < 880 ? '80%' : '65%', height: '350px', margin: windowWidth < 880 ? 'auto':'10px', boxShadow: '0px 0px 10px -7px #000000', borderRadius: '10px', padding: '20px', boxSizing: 'border-box', display: 'flex', flexDirection: 'column', justifyContent: 'end', textAlign: 'right', alignItems: 'end'}}>
                    <div ref={containerRef2} style={{width: '100%', height: '100%', position: 'absolute', top: 0, left: 0, overflow: 'hidden', zIndex: 1, filter: 'blur(1.15px)', display: 'flex', flexDirection: 'column'}}>
                        <motion.div style={{display: 'flex', flexDirection: 'column'}} animate={{y: [-containerHeight / 1.16, 0]}} transition={{y: {repeat: Infinity, repeatType: 'loop', duration: 8, ease: 'linear'}}}>
                            {fakeDepartments.map((depart, index) => (
                                <div key={index} style={{display: 'flex', flexDirection: 'row', boxShadow: '0px 0px 10px -7px #000000', alignItems: 'center', width: '70%', marginLeft: 'auto', marginRight: 'auto', borderRadius: '10px', marginTop: '20px'}}>
                                    <div style={{width: '16px', height: '16px', backgroundColor: depart.color, borderRadius: '100px', marginLeft: '20px'}}></div>
                                    <span style={{padding: '10px'}}>{depart.name}</span>
                                </div>
                            ))}
                            {fakeDepartments.map((depart, index) => (
                                <div key={`${index}-duplicate`} style={{display: 'flex', flexDirection: 'row', boxShadow: '0px 0px 10px -7px #000000', alignItems: 'center', width: '70%', marginLeft: 'auto', marginRight: 'auto', borderRadius: '10px', marginTop: '20px'}}>
                                    <div style={{width: '16px', height: '16px', backgroundColor: depart.color, borderRadius: '100px', marginLeft: '20px'}}></div>
                                    <span style={{padding: '10px'}}>{depart.name}</span>
                                </div>
                            ))}
                        </motion.div>
                    </div>
                    <img src={department} style={{width: '50px', marginBottom: '10px', zIndex: 2}}></img>
                    <span style={{fontWeight: '600', fontSize: '20px', marginBottom: '10px', zIndex: 2}}>Create Departments</span>
                    <span style={{fontWeight: '400', fontSize: '16px', letterSpacing: '-0.3px', lineHeight: 1.5, color: 'rgb(156, 163, 175)', zIndex: 2}}>Keep your prompts organized and assign different departments</span>
                </div>
                {windowWidth < 880 && <div style={{height: '30px'}}></div>}
                <div style={{position: 'relative', width: windowWidth < 880 ? '80%' : '65%', height: '350px', margin: windowWidth < 880 ? 'auto':'10px', boxShadow: '0px 0px 10px -7px #000000', borderRadius: '10px', padding: '20px', boxSizing: 'border-box', display: 'flex', flexDirection: 'column', justifyContent: 'end', textAlign: 'left'}}>
                    <div style={{width: '100%', height: '100%', position: 'absolute', top: 0, left: 0, overflow: 'hidden', zIndex: 1, filter: 'blur(0.75px) brightness(1.05)', display: 'flex', flexDirection: 'row'}}>
                        <video autoPlay muted loop style={{width: '100%', height: '70%', marginTop: '-0px'}}>
                            <source src={Share} type='video/mp4'></source>
                        </video>
                    </div>
                    <img src={share} style={{width: '50px', marginBottom: '10px', zIndex: 2}}></img>
                    <span style={{fontWeight: '600', fontSize: '20px', marginBottom: '10px', zIndex: 2}}>Share Prompts</span>
                    <span style={{fontWeight: '400', fontSize: '16px', letterSpacing: '-0.3px', lineHeight: 1.5, color: 'rgb(156, 163, 175)', zIndex: 2}}>Quickly and easily share prompts with your entire team to use</span>
                </div>
                {windowWidth < 880 && <div style={{height: '30px'}}></div>}
                <div style={{position: 'relative', width: windowWidth < 880 ? '80%' : '30%', height: '350px', margin: windowWidth < 880 ? 'auto':'10px', boxShadow: '0px 0px 10px -7px #000000', borderRadius: '10px', padding: '20px', boxSizing: 'border-box', display: 'flex', flexDirection: 'column', justifyContent: 'end', textAlign: 'right', alignItems: 'end'}}>
                    <div style={{width: '100%', height: '100%', position: 'absolute', top: 0, left: 0, overflow: 'hidden', zIndex: 1, filter: 'blur(0.75px) brightness(1.05)', display: 'flex', flexDirection: 'row'}}>
                        <video autoPlay muted loop style={{width: '100%', height: '100%', marginTop: '-130px'}}>
                            <source src={Improving} type='video/mp4'></source>
                        </video>
                    </div>
                    <img src={improve} style={{width: '50px', marginBottom: '10px', zIndex: 2}}></img>
                    <span style={{fontWeight: '600', fontSize: '20px', marginBottom: '10px', zIndex: 2}}>Improve Prompts</span>
                    <span style={{fontWeight: '400', fontSize: '16px', letterSpacing: '-0.3px', lineHeight: 1.5, color: 'rgb(156, 163, 175)', zIndex: 2}}>Collaborate together to edit and improve prompts together</span>
                </div>
            </div>

              {/*FAQ*/}
              <FAQ></FAQ>

              {/*Footer*/}
              <Footer></Footer>
            </div>
          </div>
        </div>
    )
}

export default Home
