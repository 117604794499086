import React, { Component, useState, useEffect, useRef, useLayoutEffect } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link } from 'react-router-dom'
import { motion, useAnimation } from 'framer-motion';

import Navbar from '../elements/Navbar'
import Footer from '../elements/Footer'
import FAQ from '../elements/FAQ'


const Home = (props) => {


  return (
      <div style={{width: '99.1vw', height: '100vh', backgroundColor: window.colors.white, fontFamily: 'inter', overflowX: 'hidden'}}>
        <div style={{maxWidth: '100%', display: 'flex', flexDirection: 'column', marginLeft: 'auto', marginRight: 'auto'}}>
            {/*Navbar*/}
            <Navbar></Navbar>

            <div style={{marginTop: '35px', width: '100%', textAlign: 'center'}}></div>

            <div style={{width: '70%', margin: 'auto'}}>
              <h1>Terms of Service</h1>
              <p><strong>Effective Date:</strong> 08/14/24</p>

              <p>Welcome to PromptCache.io (“we,” “our,” or “us”). These Terms of Service (“Terms”) govern your access to and use of our website and services (the “Services”). By accessing or using our Services, you agree to be bound by these Terms. If you do not agree to these Terms, do not use our Services.</p>

              <h2>1. Acceptance of Terms</h2>
              <p>By creating an account, accessing, or using our Services, you agree to comply with and be bound by these Terms and all applicable laws and regulations.</p>

              <h2>2. Services Provided</h2>
              <p>PromptCache.io is a platform for prompt management that allows users to create organizations, invite members, create departments for prompt categorization, and share prompts within the organization. Prompts refer to sets of text used in Large Language Models (LLMs).</p>

              <h2>3. Eligibility</h2>
              <p>You must be at least 18 years old to use our Services. By using our Services, you represent and warrant that you meet this age requirement.</p>

              <h2>4. User Accounts</h2>
              <p><strong>Account Creation:</strong> To access certain features of our Services, you must create an account. You are responsible for maintaining the confidentiality of your account credentials and for all activities that occur under your account.</p>
              <p><strong>Account Responsibility:</strong> You agree to provide accurate and complete information when creating an account and to keep your account information up-to-date.</p>
              <p><strong>Account Termination:</strong> We reserve the right to suspend or terminate your account at our sole discretion if you violate these Terms or engage in any conduct that we deem inappropriate or harmful to our Services or other users.</p>

              <h2>5. Organization and Department Creation</h2>
              <p><strong>Organizations:</strong> Users may create an organization within our platform. The organization’s owner has full control over its management, including inviting members and creating departments.</p>
              <p><strong>Departments:</strong> Departments within an organization are used to categorize prompts. Only members of the organization with the appropriate permissions can create and manage departments.</p>
              <p><strong>Prompt Sharing:</strong> Prompts created within an organization can be shared with other members of that organization based on the permissions set by the organization’s owner or administrators.</p>

              <h2>6. User Conduct</h2>
              <p>You agree not to:</p>
              <ul>
                  <li>Use the Services for any illegal or unauthorized purpose.</li>
                  <li>Share prompts or other content that is unlawful, harmful, threatening, abusive, defamatory, or otherwise objectionable.</li>
                  <li>Interfere with or disrupt the integrity or performance of the Services.</li>
                  <li>Attempt to gain unauthorized access to any part of the Services or related systems or networks.</li>
              </ul>

              <h2>7. Intellectual Property</h2>
              <p>All content, including but not limited to text, graphics, logos, and software, provided by PromptCache.io is the property of PromptCache.io or its licensors and is protected by intellectual property laws. You may not copy, modify, distribute, or create derivative works based on our content without our express written permission.</p>

              <h2>8. Privacy Policy</h2>
              <p>Your privacy is important to us. Please refer to our <a href="/privacy">Privacy Policy</a> for information on how we collect, use, and disclose your personal information.</p>

              <h2>9. Disclaimer of Warranties</h2>
              <p>Our Services are provided “as is” and “as available” without any warranties of any kind, either express or implied. We do not guarantee that the Services will be uninterrupted, secure, or error-free.</p>

              <h2>10. Limitation of Liability</h2>
              <p>To the fullest extent permitted by law, PromptCache.io shall not be liable for any indirect, incidental, special, consequential, or punitive damages, or any loss of profits or revenues, whether incurred directly or indirectly, or any loss of data, use, goodwill, or other intangible losses resulting from your use of our Services.</p>

              <h2>11. Indemnification</h2>
              <p>You agree to indemnify and hold harmless PromptCache.io, its officers, directors, employees, and agents from any claims, liabilities, damages, losses, or expenses, including reasonable attorneys' fees, arising out of or in any way connected with your access to or use of the Services or your violation of these Terms.</p>

              <h2>12. Modifications to the Terms</h2>
              <p>We may revise these Terms from time to time. If we make material changes, we will notify you by email or by posting a notice on our website prior to the changes becoming effective. Your continued use of the Services after the effective date of any changes constitutes your acceptance of the revised Terms.</p>

              <h2>13. Governing Law</h2>
              <p>These Terms shall be governed by and construed in accordance with the laws of the State of NJ, without regard to its conflict of law principles.</p>

              <h2>14. Contact Information</h2>
              <p>If you have any questions about these Terms, please contact us at support@thinetics.io.</p>
          </div>


            {/*FAQ*/}
            <FAQ></FAQ>

            {/*Footer*/}
            <Footer></Footer>
        </div>
      </div>
  )
}

export default Home
