import './App.css';

import React, { Component, useState, useEffect } from 'react'
import { BrowserRouter as Router, Route, Routes, withRouter, BrowserRouter } from "react-router-dom";

import Home from './components/pages/Home'
import Pricing from './components/pages/Pricing'
import Feedback from './components/pages/Feedback'
import ContactUs from './components/pages/Contact-Us'
import Privacy from './components/pages/Privacy'
import TOS from './components/pages/TOS'

function App() {

  const [colors, setColors] = useState({
    "black": "#0D0D0D",
    "blackGrey": "#B5B5B5",
    "white": "#FFFFFF",
    "whiteGrey": "#343434"
  })

  useEffect(() => {
    window.colors = colors;
  },[colors])

  window.colors = colors;

  const baseURL = 'https://prompts.thinetics.io'
  let responseFromServer = '';

  window.getServerResponse = (json) => {
    console.log(json)
    if (typeof json.detail === 'string' || json.detail instanceof String) {
      return (window.getCurrentTimeFormatted() + ': ' + json.detail)
    } else {
      return json.detail.map((error, index) => {
        return (window.getCurrentTimeFormatted() + ': ' + error.msg + ' at ' + error.loc.join(', ')).replace(/string/g, "text");
      })
    }
  }

  window.apiRequest = (endpoint, requestOptions, operation) => {
    let accessToken = localStorage.getItem('access_token');
    let refreshToken = localStorage.getItem('refresh_token');
  
    var myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Authorization", "Bearer " + refreshToken); 
    requestOptions.headers.set('Authorization', "Bearer " + accessToken);
    
    fetch(baseURL + endpoint, requestOptions)
    .then(response => {
      responseFromServer = response.status;
      return response.json();
    })
    .then(result => {
        if (responseFromServer === 401) {
            console.log('Bad Access Token, getting new one!');
            if (getNewAccessToken(refreshToken) === true) {
                console.log('continuing operation');
                window.apiRequest(endpoint, requestOptions, operation);
            } else {
                console.log('Did not get new access token');
                window.location.assign('/login');
            }
        } else {
            operation(result, responseFromServer);
        }
    })
    .catch(error => {
        console.error('Fetch error:', error);
        operation({ detail: "Cannot reach server!" }, 500);
    });
  }

  window.formatDate = (isoDate) => {
    // Parse the ISO date string into a JavaScript Date object
    const date = new Date(isoDate);
  
    // Extract the day, month, and year from the Date object
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const year = date.getFullYear();
  
    // Format the date as dd/mm/yyyy
    return `${month}/${day}/${year}`;
  }
  
  window.capFirstLetter = (str) => {
    if (!str) return str; // Check if the string is empty
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  const getNewAccessToken = async (refreshToken) => {

    var myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Authorization", "Bearer " + refreshToken); 

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        redirect: 'follow',
    }

    requestOptions.headers.set('Authorization', "Bearer " + refreshToken)
  
    fetch(baseURL + '/auth/refresh', requestOptions)
    .then(response => {responseFromServer = response.status; return response.json()})
    .then(result => {
      console.log(result)
      if (result.detail === 'Invalid Token, please logout and log back in.')
      {
        console.log('Bad Refresh Token also!')
        // window.location.assign('/login')
        return false;
      }
      else
      {
        console.log('Good Refresh Token')
        localStorage.setItem('access_token', result.access_token)
        localStorage.setItem('access_token_expires', result.access_token_expires)
        // return true;
      }
    })
    return false;
  }

  window.getCurrentTimeFormatted = () => {
    const now = new Date();

    let hours = now.getHours();
    const ampm = hours >= 12 ? 'PM' : 'AM';

    hours = hours % 12; // Convert to 12-hour format
    hours = hours ? hours : 12; // The hour '0' should be '12'

    const minutes = now.getMinutes().toString().padStart(2, '0');
    const seconds = now.getSeconds().toString().padStart(2, '0');

    return `${hours.toString().padStart(2, '0')}:${minutes}:${seconds} ${ampm}`;
  }

  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' exact element={<Home/>}>

        </Route>
        <Route path='/pricing' exact element={<Pricing/>}>

        </Route>
        <Route path='/feedback' exact element={<Feedback/>}>

        </Route>
        <Route path='/contact-us' exact element={<ContactUs/>}>

        </Route>
        <Route path='/privacy' exact element={<Privacy/>}>

        </Route>
        <Route path='/tos' exact element={<TOS/>}>

        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;