import React, { Component, useState, useEffect, useRef, useLayoutEffect } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link } from 'react-router-dom'
import { motion, useAnimation } from 'framer-motion';

import Navbar from '../elements/Navbar'
import Footer from '../elements/Footer'
import FAQ from '../elements/FAQ'


const Home = (props) => {

  const submitFeedback = async (inputEmail, inputFeedback) => {
    var myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Authorization", "Bearer " + localStorage.getItem('access_token'));
    myHeaders.append("Content-Type", "application/json");
    
    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      redirect: 'follow',
      body: JSON.stringify({
        email: inputEmail,
        feedback: inputFeedback
      })
    }
    
    window.apiRequest('/feedback', requestOptions, (result, status) => {   
      if (status === 200)
      {
        console.log(result)
      } else {setNotification(window.getServerResponse(result))}
    })
  }

  const [notification, setNotification] = useState('')
  const [email, setEmail] = useState('')
  const [feedback, setFeedback] = useState('')

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
      <div style={{width: '99.1vw', height: '100vh', backgroundColor: window.colors.white, fontFamily: 'inter', overflowX: 'hidden'}}>
        <div style={{maxWidth: '100%', display: 'flex', flexDirection: 'column', marginLeft: 'auto', marginRight: 'auto'}}>
            {/*Navbar*/}
            <Navbar></Navbar>

            <div style={{marginTop: '35px', width: '100%', textAlign: 'center'}}></div>

            <div style={{margin: 'auto', maxWidth: '1000px', marginTop: '30px', textAlign: 'center', width: '100%'}}>
              <span style={{fontWeight: '600', fontSize: '70px', letterSpacing: '-2px', lineHeight: 1.0, textAlign: 'left'}}>Contact Us</span>
            </div>
            <div style={{margin: 'auto', maxWidth: '600px', marginTop: '30px', textAlign: 'center', width: 'auto'}}>
              <span style={{fontWeight: '400', fontSize: '20px', letterSpacing: '-0.3px', lineHeight: 1.5, color: 'rgb(156, 163, 175)'}}>Sales, Bug Fixes, or just some questions. Reach out!</span>
            </div>
            <div style={{boxShadow: '0px 0px 16px -10px #000000', maxWidth: '600px', margin: 'auto', display: 'flex', flexDirection: 'column', padding: '20px', borderRadius: '20px', marginTop: '30px', width: '90%'}}>
              <div style={{borderBottom: '1px solid lightgrey', display: 'flex', flexDirection: 'row', alignItems: 'center', alignContent: 'center', paddingBottom: '10px'}}>
                <span style={{fontWeight: '600'}}>Contact Us</span>
              </div>
              <span style={{marginTop: '10px'}}>Email with Account</span>
              <input value={email} onChange={(e) => setEmail(e.target.value)} style={{border: '1px solid lightgrey', marginTop: '5px', height: '40px', borderRadius: '10px', paddingLeft: '15px'}}></input>
              <span style={{marginTop: '20px'}}>Message</span>
              <textarea value={feedback} onChange={(e) => setFeedback(e.target.value)} style={{border: '1px solid lightgrey', marginTop: '5px', height: '160px', borderRadius: '10px', padding: '10px'}}></textarea>
              <button onClick={() => submitFeedback(email, feedback)} style={{marginTop: '20px', backgroundColor: 'rgb(67, 220, 128)', paddingTop: '10px', paddingBottom: '10px', borderRadius: '10px', color: 'white', fontWeight: '600'}}>Submit</button>
            </div>

            {/*FAQ*/}
            <FAQ></FAQ>

            {/*Footer*/}
            <Footer></Footer>
        </div>
      </div>
  )
}

export default Home
