import React, { Component, useState, useEffect, useRef, useLayoutEffect } from 'react'
import Accordion from 'react-bootstrap/Accordion';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link, useLocation } from 'react-router-dom'
import { motion, useAnimation } from 'framer-motion';

import Logo from '../assets/PromptLogo.png'
import tempVideo from '../assets/tempVideoPlaceholder.png'

import prompt1 from '../assets/prompt1.png'
import prompt2 from '../assets/prompt2.png'

import doc from '../assets/document.png'
import department from '../assets/department.png'
import share from '../assets/share.png'
import improve from '../assets/improve.png'

import discord from '../assets/discord.png'
import twitter from '../assets/twitter.png'

import np1 from '../assets/new-prompt1.png'
import np2 from '../assets/new-prompt2.png'
import np3 from '../assets/new-prompt3.png'

import HeroVideo from '../assets/FinishVideo.mp4'
import Share from '../assets/Sharing.mp4'
import Improving from '../assets/Improving.mp4'

import Check from '../assets/Checkmark.png'

import Navbar from '../elements/Navbar'
import Footer from '../elements/Footer'
import FAQ from '../elements/FAQ'

const Home = (props) => {

  const controls = useAnimation();
  const location = useLocation(); 
  const [key, setKey] = useState(0); // State to force re-render

    const prompts = [
      { department: 'Engineering', title: 'Prepare Proposal for Client', date: 'Aug 4, 2024', initials: 'JB', color: '#6B8E23' },
      { department: 'Marketing', title: 'Create Social Media Strategy', date: 'Sep 10, 2024', initials: 'AL', color: '#4682B4' },
      { department: 'Sales', title: 'Develop Sales Pitch', date: 'Jul 15, 2024', initials: 'MR', color: '#DAA520' },
      { department: 'HR', title: 'Organize Team Building Event', date: 'Nov 2, 2024', initials: 'KM', color: '#FF6347' },
      { department: 'Finance', title: 'Analyze Quarterly Financials', date: 'Dec 20, 2024', initials: 'CG', color: '#5F9EA0' },
      { department: 'Engineering', title: 'Code Review for New Feature', date: 'Aug 18, 2024', initials: 'SD', color: '#8A2BE2' },
      { department: 'Design', title: 'Update Website UI', date: 'Oct 5, 2024', initials: 'LH', color: '#D2691E' },
      { department: 'Product', title: 'Research Market Trends', date: 'Jan 25, 2024', initials: 'ZK', color: '#556B2F' },
      { department: 'Operations', title: 'Optimize Supply Chain', date: 'May 14, 2024', initials: 'TW', color: '#8B4513' },
      { department: 'Customer Support', title: 'Implement New Support Protocols', date: 'Apr 22, 2024', initials: 'EN', color: '#FF4500' },
      { department: 'Engineering', title: 'Deploy Software Update', date: 'Jun 30, 2024', initials: 'AV', color: '#2E8B57' },
      { department: 'Marketing', title: 'Launch Email Campaign', date: 'Jul 9, 2024', initials: 'DF', color: '#B22222' },
      { department: 'Sales', title: 'Prepare Client Presentation', date: 'Feb 13, 2024', initials: 'BT', color: '#9400D3' },
      { department: 'HR', title: 'Conduct Employee Survey', date: 'Mar 1, 2024', initials: 'MJ', color: '#FFD700' },
      { department: 'Finance', title: 'Review Budget Allocations', date: 'Sep 28, 2024', initials: 'RS', color: '#FF1493' },
      { department: 'Design', title: 'Create New Brand Assets', date: 'Nov 15, 2024', initials: 'PL', color: '#00CED1' },
      { department: 'Product', title: 'Develop Product Roadmap', date: 'Dec 3, 2024', initials: 'HN', color: '#4B0082' },
      { department: 'Operations', title: 'Audit Operational Processes', date: 'Oct 21, 2024', initials: 'VG', color: '#20B2AA' },
      { department: 'Customer Support', title: 'Improve Response Times', date: 'May 7, 2024', initials: 'KY', color: '#FF8C00' },
      { department: 'Engineering', title: 'Implement Security Enhancements', date: 'Aug 25, 2024', initials: 'NW', color: '#4682B4' },
    ];
  
    useLayoutEffect(() => {
      let isActive = true;
  
      const sequence = async () => {
        if (isActive) {
          // Pan to the right
          await controls.start({
            x: -2750,
            transition: { duration: 35, ease: 'linear' },
          });
  
          // Pan back to the left
          await controls.start({
            x: 2750,
            transition: { duration: 35, ease: 'linear' },
          });
        }
      };
  
      if (isActive) {
        sequence();
      }
  
      return () => {
        controls.stop();
        isActive = false;
      };
    }, [controls, key]); // Include 'key' to trigger reinitialization
  
    useEffect(() => {
      // Trigger a key change whenever the location changes, forcing the animation to reset
      setKey(prevKey => prevKey + 1);
    }, [location]);

  const images = [np1, np2, np3];

  const containerRef = React.useRef(null);
  const [containerWidth, setContainerWidth] = React.useState(0);

  const containerRef2 = React.useRef(null);
  const [containerHeight, setContainerHeight] = React.useState(0);

  const fakeDepartments = [
    { name: 'Marketing', color: '#4682B4' },
    { name: 'Sales', color: '#FF8C00' },
    { name: 'HR', color: '#20B2AA' },
    { name: 'Engineering', color: '#4B0082' },
    { name: 'Finance', color: '#00CED1' },
    { name: 'Support', color: '#FF1493' },
    { name: 'IT', color: '#FFD700' },
  ];
  
  React.useEffect(() => {
    if (containerRef.current) {
      setContainerWidth(containerRef.current.scrollWidth);
    }
    if (containerRef2.current) {
      setContainerHeight(containerRef2.current.scrollWidth);
    }
  }, []);

  const contentRef = useRef(null)

  const scrollToContent = () => {
    if (contentRef.current) {
      contentRef.current.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
          inline: 'center',
      });
    }
  };

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

    return (
        <div style={{width: '99.1vw', height: '100vh', backgroundColor: window.colors.white, fontFamily: 'inter', overflowX: 'hidden'}}>
          <div style={{maxWidth: '100%', display: 'flex', flexDirection: 'column', marginLeft: 'auto', marginRight: 'auto'}}>
            
            {/*Navbar*/}
            <Navbar></Navbar>

            <div style={{marginTop: '35px', width: '100%', textAlign: 'center'}}>

            {/*Hero*/}
            <div style={{margin: 'auto', maxWidth: '780px', marginTop: '30px', width: '100%'}}>
              <span style={{fontWeight: '600', fontSize: '70px', letterSpacing: '-2px', lineHeight: 1.0}}>Optimize your Business</span>
            </div>
            <div style={{margin: 'auto', maxWidth: '600px', marginTop: '30px', width: '100%'}}>
              <span style={{fontWeight: '400', fontSize: '20px', letterSpacing: '-0.3px', lineHeight: 1.5, color: 'rgb(156, 163, 175)'}}>Choose the package that best suites your needs</span>
            </div>
            <div style={{width: '100%', margin: 'auto', display: 'flex', flexDirection: windowWidth < 1140 ? 'column':'row', maxWidth: '1300px'}}>
              <div style={{display: 'flex', flexDirection: 'column', border: '1px solid rgb(229, 231, 235)', margin: 'auto', padding: '30px 40px 30px 40px', textAlign: 'left', alignItems: 'start', marginTop: '30px', borderRadius: '20px'}}>
                <span style={{fontSize: '24px', fontWeight: '600'}}>Independant</span>
                <span style={{color: 'rgb(156, 163, 175)'}}>For yourself</span>
                <span style={{marginTop: '20px', fontSize: '42px', fontWeight: '600'}}>Free <span style={{color: 'rgb(156, 163, 175)', fontSize: '20px'}}>/ month</span></span>
                <button style={{backgroundColor: 'rgb(67, 220, 128)', color: 'white', padding: '10px 20px 10px 20px', width: '100%', borderRadius: '10px', marginTop: '20px'}}>Choose Independant</button>
                <div style={{height: '30px', borderBottom: '1px solid rgb(229, 231, 235)', width: '100%', marginBottom: '30px'}}></div>
                <div style={{display: 'flex', flexDirection: 'row'}}>
                  <div className='center' style={{width: '24px', height: '24px', backgroundColor: 'rgb(67, 220, 128)', borderRadius: '100px'}}>
                    <img style={{width: '100%'}} src={Check}></img>
                  </div>
                  <span style={{marginLeft: '10px'}}>A single seat for the best person!</span>
                </div>
                <div style={{display: 'flex', flexDirection: 'row', marginTop: '10px'}}>
                  <div className='center' style={{width: '24px', height: '24px', backgroundColor: 'rgb(67, 220, 128)', borderRadius: '100px'}}>
                    <img style={{width: '100%'}} src={Check}></img>
                  </div>
                  <span style={{marginLeft: '10px'}}>Prompt Categories</span>
                </div>
                <div style={{display: 'flex', flexDirection: 'row', marginTop: '10px'}}>
                  <div className='center' style={{width: '24px', height: '24px', backgroundColor: 'rgb(67, 220, 128)', borderRadius: '100px'}}>
                    <img style={{width: '100%'}} src={Check}></img>
                  </div>
                  <span style={{marginLeft: '10px'}}>Creating/Saving/Editing Prompts</span>
                </div>
                <div style={{display: 'flex', flexDirection: 'row', marginTop: '10px'}}>
                  <div className='center' style={{width: '24px', height: '24px', backgroundColor: 'rgb(67, 220, 128)', borderRadius: '100px'}}>
                    <img style={{width: '100%'}} src={Check}></img>
                  </div>
                  <span style={{marginLeft: '10px'}}>Unlimited Total Prompts</span>
                </div>
              </div>
              <div style={{display: 'flex', flexDirection: 'column', border: '1px solid rgb(229, 231, 235)', margin: 'auto', padding: '30px 40px 30px 40px', textAlign: 'left', alignItems: 'start', marginTop: '30px', borderRadius: '20px'}}>
                <span style={{fontSize: '24px', fontWeight: '600'}}>Small Business</span>
                <span style={{color: 'rgb(156, 163, 175)'}}>For you and a couple friends</span>
                <span style={{marginTop: '20px', fontSize: '42px', fontWeight: '600'}}>$9.99 <span style={{color: 'rgb(156, 163, 175)', fontSize: '20px'}}>/ month</span></span>
                {/* <span style={{marginTop: '0px', fontSize: '24px', fontWeight: '600'}}>+$3.99 <span style={{color: 'rgb(156, 163, 175)', fontSize: '20px'}}>/ month per seat</span></span> */}
                <button style={{backgroundColor: 'rgb(67, 220, 128)', color: 'white', padding: '10px 20px 10px 20px', width: '100%', borderRadius: '10px', marginTop: '20px'}}>Choose Small Business</button>
                <div style={{height: '30px', borderBottom: '1px solid rgb(229, 231, 235)', width: '100%', marginBottom: '30px'}}></div>
                <div style={{display: 'flex', flexDirection: 'row'}}>
                  <div className='center' style={{width: '24px', height: '24px', backgroundColor: 'rgb(67, 220, 128)', borderRadius: '100px'}}>
                    <img style={{width: '100%'}} src={Check}></img>
                  </div>
                  <span style={{marginLeft: '10px'}}>Up to 10 seats!</span>
                </div>
                <div style={{display: 'flex', flexDirection: 'row', marginTop: '10px'}}>
                  <div className='center' style={{width: '24px', height: '24px', backgroundColor: 'rgb(67, 220, 128)', borderRadius: '100px'}}>
                    <img style={{width: '100%'}} src={Check}></img>
                  </div>
                  <span style={{marginLeft: '10px'}}>Prompt Categories</span>
                </div>
                <div style={{display: 'flex', flexDirection: 'row', marginTop: '10px'}}>
                  <div className='center' style={{width: '24px', height: '24px', backgroundColor: 'rgb(67, 220, 128)', borderRadius: '100px'}}>
                    <img style={{width: '100%'}} src={Check}></img>
                  </div>
                  <span style={{marginLeft: '10px'}}>Creating/Saving/Editing Prompts</span>
                </div>
                <div style={{display: 'flex', flexDirection: 'row', marginTop: '10px'}}>
                  <div className='center' style={{width: '24px', height: '24px', backgroundColor: 'rgb(67, 220, 128)', borderRadius: '100px'}}>
                    <img style={{width: '100%'}} src={Check}></img>
                  </div>
                  <span style={{marginLeft: '10px'}}>User Permission System</span>
                </div>
                <div style={{display: 'flex', flexDirection: 'row', marginTop: '10px'}}>
                  <div className='center' style={{width: '24px', height: '24px', backgroundColor: 'rgb(67, 220, 128)', borderRadius: '100px'}}>
                    <img style={{width: '100%'}} src={Check}></img>
                  </div>
                  <span style={{marginLeft: '10px'}}>Prebuilt Prompts</span>
                </div>
                <div style={{display: 'flex', flexDirection: 'row', marginTop: '10px'}}>
                  <div className='center' style={{width: '24px', height: '24px', backgroundColor: 'rgb(67, 220, 128)', borderRadius: '100px'}}>
                    <img style={{width: '100%'}} src={Check}></img>
                  </div>
                  <span style={{marginLeft: '10px'}}>3 Custom Prompts</span>
                </div>
                <div style={{display: 'flex', flexDirection: 'row', marginTop: '10px'}}>
                  <div className='center' style={{width: '24px', height: '24px', backgroundColor: 'rgb(67, 220, 128)', borderRadius: '100px'}}>
                    <img style={{width: '100%'}} src={Check}></img>
                  </div>
                  <span style={{marginLeft: '10px'}}>All Previous Tier Features</span>
                </div>
              </div>
              <div style={{display: 'flex', flexDirection: 'column', border: '1px solid rgb(229, 231, 235)', margin: 'auto', padding: '30px 40px 30px 40px', textAlign: 'left', alignItems: 'start', marginTop: '30px', borderRadius: '20px'}}>
                <span style={{fontSize: '24px', fontWeight: '600'}}>Enterprise</span>
                <span style={{color: 'rgb(156, 163, 175)'}}>Unlimited Power for your employees</span>
                <span style={{marginTop: '20px', fontSize: '42px', fontWeight: '600'}}>$19.99 <span style={{color: 'rgb(156, 163, 175)', fontSize: '20px'}}>/ month</span></span>
                {/* <span style={{marginTop: '0px', fontSize: '24px', fontWeight: '600'}}>+$2.99 <span style={{color: 'rgb(156, 163, 175)', fontSize: '20px'}}>/ month per seat</span></span> */}
                <button style={{backgroundColor: 'rgb(67, 220, 128)', color: 'white', padding: '10px 20px 10px 20px', width: '100%', borderRadius: '10px', marginTop: '20px'}}>Choose Enterprise</button>
                <div style={{height: '30px', borderBottom: '1px solid rgb(229, 231, 235)', width: '100%', marginBottom: '30px'}}></div>
                <div style={{display: 'flex', flexDirection: 'row'}}>
                  <div className='center' style={{width: '24px', height: '24px', backgroundColor: 'rgb(67, 220, 128)', borderRadius: '100px'}}>
                    <img style={{width: '100%'}} src={Check}></img>
                  </div>
                  <span style={{marginLeft: '10px'}}>Unlimited Seats</span>
                </div>
                <div style={{display: 'flex', flexDirection: 'row', marginTop: '10px'}}>
                  <div className='center' style={{width: '24px', height: '24px', backgroundColor: 'rgb(67, 220, 128)', borderRadius: '100px'}}>
                    <img style={{width: '100%'}} src={Check}></img>
                  </div>
                  <span style={{marginLeft: '10px'}}>Prompt Categories</span>
                </div>
                <div style={{display: 'flex', flexDirection: 'row', marginTop: '10px'}}>
                  <div className='center' style={{width: '24px', height: '24px', backgroundColor: 'rgb(67, 220, 128)', borderRadius: '100px'}}>
                    <img style={{width: '100%'}} src={Check}></img>
                  </div>
                  <span style={{marginLeft: '10px'}}>Creating/Saving/Editing Prompts</span>
                </div>
                <div style={{display: 'flex', flexDirection: 'row', marginTop: '10px'}}>
                  <div className='center' style={{width: '24px', height: '24px', backgroundColor: 'rgb(67, 220, 128)', borderRadius: '100px'}}>
                    <img style={{width: '100%'}} src={Check}></img>
                  </div>
                  <span style={{marginLeft: '10px'}}>User Permission System</span>
                </div>
                <div style={{display: 'flex', flexDirection: 'row', marginTop: '10px'}}>
                  <div className='center' style={{width: '24px', height: '24px', backgroundColor: 'rgb(67, 220, 128)', borderRadius: '100px'}}>
                    <img style={{width: '100%'}} src={Check}></img>
                  </div>
                  <span style={{marginLeft: '10px'}}>Prebuilt Prompts</span>
                </div>
                <div style={{display: 'flex', flexDirection: 'row', marginTop: '10px'}}>
                  <div className='center' style={{width: '24px', height: '24px', backgroundColor: 'rgb(67, 220, 128)', borderRadius: '100px'}}>
                    <img style={{width: '100%'}} src={Check}></img>
                  </div>
                  <span style={{marginLeft: '10px'}}>10 Custom Prompts</span>
                </div>
                <div style={{display: 'flex', flexDirection: 'row', marginTop: '10px'}}>
                  <div className='center' style={{width: '24px', height: '24px', backgroundColor: 'rgb(67, 220, 128)', borderRadius: '100px'}}>
                    <img style={{width: '100%'}} src={Check}></img>
                  </div>
                  <span style={{marginLeft: '10px'}}>Set-up Support</span>
                </div>
                <div style={{display: 'flex', flexDirection: 'row', marginTop: '10px'}}>
                  <div className='center' style={{width: '24px', height: '24px', backgroundColor: 'rgb(67, 220, 128)', borderRadius: '100px'}}>
                    <img style={{width: '100%'}} src={Check}></img>
                  </div>
                  <span style={{marginLeft: '10px'}}>Prompt Engineer Support</span>
                </div>
                <div style={{display: 'flex', flexDirection: 'row', marginTop: '10px'}}>
                  <div className='center' style={{width: '24px', height: '24px', backgroundColor: 'rgb(67, 220, 128)', borderRadius: '100px'}}>
                    <img style={{width: '100%'}} src={Check}></img>
                  </div>
                  <span style={{marginLeft: '10px'}}>All Previous Tier Features</span>
                </div>
              </div>
            </div>

            {/*FAQ*/}
            <FAQ></FAQ>

            {/*Footer*/}
            <Footer></Footer>
          </div>
        </div>
      </div>
    )
}

export default Home
