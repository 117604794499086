import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Logo2 from '../assets/PromptLogo.png';
import MenuIcon2 from '../assets/menu.png';

const Navbar = ({ Logo, MenuIcon }) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 800);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 800);
      if (window.innerWidth >= 800) {
        setIsMenuOpen(false);
      }
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <div style={{ width: '100%', maxWidth: '1100px', margin: 'auto', position: 'sticky', top: '20px', backgroundColor: 'rgba(229, 231, 235, 0.2)', display: 'flex', flexDirection: 'row', fontFamily: 'inter', padding: '20px', alignItems: 'center', borderRadius: '20px', zIndex: 3, backdropFilter: 'blur(3px)' }}>
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        {!isMobile && (
          <>
            <Link to='/pricing' style={{ marginRight: '30px', color: 'rgba(0,0,0,0.99)', marginLeft: '20px', textDecoration: 'none' }}>Pricing</Link>
            <Link to='/feedback' style={{ marginRight: '30px', color: 'rgba(0,0,0,0.99)', textDecoration: 'none' }}>Feedback</Link>
            <Link to='/contact-us' style={{ marginRight: '30px', color: 'rgba(0,0,0,0.99)', textDecoration: 'none' }}>Contact Us</Link>
          </>
        )}
      </div>
      <Link to='/' style={{margin: !isMobile && 'auto', width: '15%', minWidth: '150px' }}>
        <img style={{ width: '100%' }} src={Logo2} alt="Logo" />
      </Link>
      <div style={{ width: '33%', display: 'flex', alignItems: 'center', justifyContent: 'flex-end', marginLeft: isMobile && 'auto' }}>
        <Link to='https://app.promptcache.io/login' style={{ fontFamily: 'inter', backgroundColor: window.colors.black, color: window.colors.white, padding: '10px 20px', fontWeight: '600', borderRadius: '5px', textDecoration: 'none', minWidth: '110px' }}>Try Now!</Link>
        {isMobile && (
          <img src={MenuIcon2} alt="Menu" style={{ width: '40px', height: '40px', marginLeft: '40px', cursor: 'pointer' }} onClick={toggleMenu} />
        )}
      </div>
      {isMobile && isMenuOpen && (
        <div id="nav-links-mobile" style={{ display: 'flex', flexDirection: 'column', position: 'absolute', right: '20px', top: '60px', backgroundColor: 'rgba(229, 231, 235, 0.9)', padding: '10px', borderRadius: '10px', boxShadow: '0px 4px 6px rgba(0,0,0,0.1)' }}>
          <Link to='/pricing' style={{ marginBottom: '10px', color: 'rgba(0,0,0,0.99)', textDecoration: 'none' }}>Pricing</Link>
          <Link to='/feedback' style={{ marginBottom: '10px', color: 'rgba(0,0,0,0.99)', textDecoration: 'none' }}>Feedback</Link>
          <Link to='/contact-us' style={{ color: 'rgba(0,0,0,0.99)', textDecoration: 'none' }}>Contact Us</Link>
        </div>
      )}
    </div>
  );
};

export default Navbar;
