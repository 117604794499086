import React, { Component, useState, useEffect, useRef, useLayoutEffect } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link } from 'react-router-dom'
import { motion, useAnimation } from 'framer-motion';
import Logo from '../assets/PromptLogo.png'

import discord from '../assets/discord.png'
import twitter from '../assets/twitter.png'

const Home = (props) => {

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div style={{margin: 'auto', width: '100%', marginTop: '100px', borderTop: '1px solid rgb(226, 232, 240)', display: 'flex', flexDirection: 'column'}}>
      <div style={{margin: 'auto', width: '50%', display: 'flex', flexDirection: windowWidth < 875 ? 'column':'row', marginTop: '40px', alignItems: 'start'}}>
        <div style={{display: 'flex', flexDirection: 'column'}}>
          <img src={Logo} style={{width: '200px'}}></img>
          <Link className='center' to='https://app.promptcache.io/login' style={{fontFamily: 'inter', backgroundColor: window.colors.black, color: window.colors.white, padding: '10px 20px 10px 20px', fontWeight: '600', borderRadius: '500px', float: 'right', marginTop: '20px', textDecoration: 'none'}}><span>Get Started!</span></Link>
          <div style={{display: 'flex', flexDirection: 'row', marginTop: '20px'}}>
            {/* <img src={discord} style={{width: '25px', cursor: 'pointer'}}></img> */}
            <a href="https://x.com/thinetics" target="_blank"><img src={twitter} style={{width: '25px', marginLeft: '0px', cursor: 'pointer'}}></img></a>
          </div>
        </div>
        {windowWidth < 875 && <div style={{height: '30px'}}></div>}
        <div style={{display: 'flex', flexDirection: 'column', marginLeft: windowWidth < 875 ? '0':'auto', textAlign: 'left', marginRight: '50px', fontWeight: '500'}}>
          <span>NAV</span>
          <Link to='/pricing' style={{color: 'grey', marginTop: '20px', fontSize: '14px', cursor: 'pointer', textDecoration: 'none'}}>Pricing</Link>
          {/* <Link to='/blog' style={{color: 'grey', marginTop: '10px', fontSize: '14px', cursor: 'pointer', textDecoration: 'none'}}>Blog</Link> */}
          <Link to='/feedback' style={{color: 'grey', marginTop: '10px', fontSize: '14px', cursor: 'pointer', textDecoration: 'none'}}>Feedback</Link>
          <Link to='https://app.promptcache.io/login' style={{color: 'grey', marginTop: '10px', fontSize: '14px', cursor: 'pointer', textDecoration: 'none'}}>Sign In</Link>
        </div>
        {windowWidth < 875 && <div style={{height: '30px'}}></div>}
        <div style={{display: 'flex', flexDirection: 'column', textAlign: 'left', fontWeight: '500', marginBottom: '50px'}}>
          <span>USER</span>
          <Link to='/contact-us' style={{color: 'grey', marginTop: '20px', fontSize: '14px', cursor: 'pointer', textDecoration: 'none'}}>Support</Link>
          <Link to='/privacy' style={{color: 'grey', marginTop: '10px', fontSize: '14px', cursor: 'pointer', textDecoration: 'none'}}>Privacy Policy</Link>
          <Link to='/tos' style={{color: 'grey', marginTop: '10px', fontSize: '14px', cursor: 'pointer', textDecoration: 'none'}}>TOS</Link>
        </div>
      </div>
      <div style={{borderTop: '1px solid rgb(226, 232, 240)', width: '80%', margin: 'auto', textAlign: 'center', maxWidth: '1200px'}}>
        <div style={{marginTop: '10px'}}></div>
        <span style={{padding: '10px', color: 'grey'}}>Copyright © 2024 PromptCache. Product of <a href='https://thinetics.io' target="_blank">Thinetics.io</a>, Inc. All Rights Reserved.</span>
      </div>
    </div>
  )
}

export default Home
